import React from "react"

import PropTypes from "prop-types"
import styled from "styled-components"

import StepCircle from "../../../common/StepCircle"

const StyledMvpStep = styled.div`
  color: ${({ theme }) => theme.color.text.default};
  font-size: 1.5rem;
  display: grid;
  grid-template-rows: 150px auto;
  grid-gap: 1.5rem;
  justify-items: center;
  align-items: center;

  ${({ theme }) => theme.mq.xs} {
    display: grid;
    grid-template-rows: unset;
    grid-gap: 0 3.5rem;
  }
  StepCircle ${({ theme }) => theme.mq.xxs} {
    grid-gap: 0 2.5rem;
  }
`

const StyledDescription = styled.div`
  height: 100%;
  display: grid;
  grid-gap: 1.5rem;
  align-self: flex-start;

  ${({ theme }) => theme.mq.xs} {
    grid-template-rows: unset;
  }

  p {
    align-items: center;
    color: ${({ theme }) => theme.color.text.default};
    display: flex;
    flex-grow: 1;
    font-size: 1.4rem;
    font-weight: 500;
    height: 100%;
    line-height: 2rem;
    position: relative;
    text-align: left;
    width: 100%;
  }
`
const StyledHeader = styled.h3`
  color: ${({ color }) => color};
  font-weight: 900;
  padding: 0;
  text-align: center;
  text-transform: uppercase;

  ${({ theme }) => theme.mq.xs} {
    padding: 0;
  }
`

const MvpStep = ({ number, title, content, circleColor, textColor, headerColor, isFirst }) => (
  <StyledMvpStep>
    <StepCircle circleColor={circleColor} isFirst={isFirst} isSlider number={number} textColor={textColor} />
    <StyledDescription>
      <StyledHeader color={headerColor}>{title}</StyledHeader>
      {content && <p>{content}</p>}
    </StyledDescription>
  </StyledMvpStep>
)

MvpStep.propTypes = {
  circleColor: PropTypes.string,
  content: PropTypes.string,
  headerColor: PropTypes.string,
  isFirst: PropTypes.bool.isRequired,
  number: PropTypes.string.isRequired,
  textColor: PropTypes.string,
  title: PropTypes.string.isRequired,
}

MvpStep.defaultProps = {
  circleColor: "#fff",
  content: "",
  headerColor: "#fff",
  textColor: "#03c2de",
}

export default MvpStep
