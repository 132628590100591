import React, { useState } from "react"

import { marked } from "marked"
import PropTypes from "prop-types"
import { config, useSpring, animated } from "react-spring"
import styled from "styled-components"

import Container from "components/common/Container"
import Section from "components/common/Section"
import Slider from "components/common/Slider"

import MvpStep from "../MvpStep/MvpStep"

const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ childrenLength }) => `repeat(${childrenLength / 2}, minmax(0, 1fr))`};
  column-gap: 2rem;
  margin-right: 40px;

  ${({ theme }) => theme.mq.xs} {
    display: none;
  }
`

const StyledSlideContainer = styled.div`
  display: grid;
  grid-gap: 5rem;
  height: 100%;
`

const StyledWrapper = styled.div`
  display: none;

  ${({ theme }) => theme.mq.xs} {
    display: block;
  }
`

const StyledDescription = styled.p`
  color: ${({ theme }) => theme.color.text.inverted};
  font-size: 1.6rem;
  text-align: center;
  padding: 0 3rem 3rem;
`

const MvpDescription = ({ data }) => {
  const [isVisible, setIsVisible] = useState(false)
  const slideSpring = useSpring({ config: config.stiff, y: isVisible ? 0 : 250, opacity: isVisible ? 1 : 0 })

  return (
    <Section animatedTitle={data.page_title} color="cyan" onIntersectChange={setIsVisible}>
      <Container>
        <animated.div
          style={{
            transform: `${slideSpring.y.interpolate(dy => `translate3d(0,${dy}px,0)`)}`,
            ...slideSpring,
          }}
        >
          <StyledDescription dangerouslySetInnerHTML={{ __html: marked.parse(data.page_description) }} />
          {/* Mobile */}
          <StyledWrapper>
            <Slider>
              {data.mvp_steps.map(({ id, title }, index) => (
                <>
                  <StyledSlideContainer
                    style={{ transform: slideSpring.y.interpolate(dy => `translate3d(0,${dy}px,0)`), ...slideSpring }}
                  >
                    <MvpStep key={id} isFirst={index === 0} number={index + 1} title={title} />
                  </StyledSlideContainer>
                </>
              ))}
            </Slider>
          </StyledWrapper>
          {/* Desktop */}
          <StyledContainer childrenLength={data.mvp_steps.length}>
            {data.mvp_steps.map(({ id, title }, index) => (
              <animated.div
                style={{ transform: slideSpring.y.interpolate(dy => `translate3d(0,${dy}px,0)`), ...slideSpring }}
              >
                <MvpStep key={id} isFirst={index === 0} number={index + 1} title={title} />
              </animated.div>
            ))}
          </StyledContainer>
        </animated.div>
      </Container>
    </Section>
  )
}

MvpDescription.propTypes = {
  data: PropTypes.shape({
    page_title: PropTypes.string.isRequired,
    page_description: PropTypes.string.isRequired,
  }).isRequired,
}

export default MvpDescription
