/* eslint-disable no-shadow */
import React, { useState } from "react"

import { StaticImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import { config, useSpring, animated } from "react-spring"
import styled, { useTheme } from "styled-components"

import Container from "components/common/Container"
import Section from "components/common/Section"
import Slider from "components/common/Slider"

import MvpStep from "../MvpStep/MvpStep"

const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ childrenLength }) => `repeat(${childrenLength / 2}, minmax(0, 1fr))`};
  column-gap: 2rem;
  margin-right: 40px;

  ${({ theme }) => theme.mq.xs} {
    grid-template-columns: auto;
    display: none;
  }
`

const StyledDescription = styled.p`
  font-size: 1.6rem;
  text-align: center;
  padding: 0 3rem 3rem;
`

const StyledImageWrapper = styled.div`
  grid-column: 1/4;
  position: relative;
  margin: 50px 0;
`

const StyledProcessItem = styled.div`
  ${({ theme }) => `
  display: block;
  position: absolute;
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 600;
  color: ${theme.color.text.inverted};
  top: 88%;

  &:nth-of-type(2) {
    left: 4%;
  }
  &:nth-of-type(3) {
    left: 25%;
  }
  &:nth-of-type(4) {
    left: 47%;
    top: 58%;
    width: 80px;
    text-align: center;
    color: #000;
  }
  &:nth-of-type(5) {
    left: 47%;
    top: 16%;
    width: 80px;
    text-align: center;
    color: #000;
  }
  &:nth-of-type(6) {
    left: 63%;
  }
  &:nth-of-type(7) {
    left: 82%;
  }

  
  ${theme.mq.m} {
    top: 87%;
    font-size: 1.3rem;

    &:nth-of-type(4) {
      left: 46%;
    }
    &:nth-of-type(5) {
      left: 46.5%;
    }
  }

  ${theme.mq.xs} {
    left: 40%;
    color: ${theme.color.text.default};
    font-size: 2rem;

    &:nth-of-type(2) {
      top: 10%;
      left: 200px;
    }
    &:nth-of-type(3) {
      top: 30%;
      left: 200px;
    }
    &:nth-of-type(4) {
      left: 152px;
      top: 48%;
    }
    &:nth-of-type(5) {
      left: 354px;
      top: 48%;
    }
    &:nth-of-type(6) {
      top: 70%;
      left: 200px;
    }
    &:nth-of-type(7) {
      left: 200px;
    }
  }

  ${theme.mq.xxs} {
    &:nth-of-type(2) {
      top: 10%;
      left: 100px;
    }
    &:nth-of-type(3) {
      top: 30%;
      left: 100px;
    }
    &:nth-of-type(4) {
      left: 28%;
      top: 47%;
    }
    &:nth-of-type(5) {
      left: 67%;
      top: 48%;
    }
    &:nth-of-type(6) {
      top: 70%;
      left: 100px;
    }
    &:nth-of-type(7) {
      left: 100px;
    }
  }
`}
`

const StyledWrapper = styled.div`
  display: none;

  ${({ theme }) => theme.mq.xs} {
    display: block;
  }
`

const colorResolver = (index, color) => {
  switch (index) {
    case 0:
      return color.cyan_300
    case 1:
      return color.cyan_300
    case 2:
      return color.cyan_300
    case 3:
      return color.cyan_800
    case 4:
      return color.purple_500
    case 5:
      return color.purple_700
    default:
      return color.cyan_300
  }
}

const MvpProcess = ({ data }) => {
  const theme = useTheme()
  const [isVisible, setIsVisible] = useState(false)
  const slideSpring = useSpring({ config: config.stiff, y: isVisible ? 0 : 250, opacity: isVisible ? 1 : 0 })

  return (
    <Section animatedTitle={data.title} onIntersectChange={setIsVisible}>
      <Container>
        <animated.div
          style={{ transform: slideSpring.y.interpolate(dy => `translate3d(0,${dy}px,0)`), ...slideSpring }}
        >
          <StyledDescription>{data.description}</StyledDescription>
        </animated.div>
        <animated.div
          style={{ transform: slideSpring.y.interpolate(dy => `translate3d(0,${dy}px,0)`), ...slideSpring }}
        >
          <StyledWrapper>
            <Slider variant="cyan">
              {data.mvp_process_step.slice(0, 3).map(({ id, title, description }, index) => (
                <>
                  <MvpStep
                    key={id}
                    circleColor={colorResolver(index, theme.color)}
                    content={description}
                    headerColor={theme.color.text.default}
                    isFirst={index === 0}
                    number={index + 1}
                    textColor={theme.color.text.inverted}
                    title={title}
                  />
                </>
              ))}
            </Slider>
            <StyledImageWrapper>
              <StaticImage
                alt="MVP Process"
                loading="lazy"
                placeholder="MVP Process"
                src="../../../../assets/svg/mvp-graf-vertical.svg"
              />
              {data.mvp_process_arrow.map(({ id, title }) => (
                <StyledProcessItem key={id}>{title}</StyledProcessItem>
              ))}
            </StyledImageWrapper>
            <Slider variant="cyan">
              {data.mvp_process_step.map(
                ({ id, title, description }, index) =>
                  index > 2 && (
                    <>
                      <MvpStep
                        key={id}
                        circleColor={colorResolver(index, theme.color)}
                        content={description}
                        headerColor={theme.color.text.default}
                        isFirst={index === 0}
                        number={index + 1}
                        textColor={theme.color.text.inverted}
                        title={title}
                      />
                    </>
                  )
              )}
            </Slider>
          </StyledWrapper>
          <StyledContainer childrenLength={data.mvp_process_step.length}>
            {data.mvp_process_step.map(({ id, title, description }, index) => (
              <>
                <MvpStep
                  key={id}
                  circleColor={colorResolver(index, theme.color)}
                  content={description}
                  headerColor={theme.color.text.default}
                  isFirst={index === 0}
                  number={index + 1}
                  textColor={theme.color.text.inverted}
                  title={title}
                />
                {index === 2 && (
                  <StyledImageWrapper>
                    <StaticImage
                      alt="MVP Process"
                      loading="lazy"
                      placeholder="MVP Process"
                      src="../../../../assets/svg/mvp-graf.svg"
                    />
                    {data.mvp_process_arrow.map(({ id, title }) => (
                      <StyledProcessItem key={id}>{title}</StyledProcessItem>
                    ))}
                  </StyledImageWrapper>
                )}
              </>
            ))}
          </StyledContainer>
        </animated.div>
      </Container>
    </Section>
  )
}

MvpProcess.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
}

export default MvpProcess
