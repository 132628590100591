import React from "react"

import PropTypes from "prop-types"
import styled from "styled-components"

import Container from "components/common/Container"
import GeneralText from "components/common/GeneralText"

const StyledContainer = styled.div`
  display: block;
  background: #fff;
  text-align: center;
  padding: 0 0 6.5rem;
`

const StyledButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 2rem;

  ${({ theme }) => theme.mq.xs} {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`

const StyledOption = styled.div`
  padding: 40px 0;
  ${({ theme }) => theme.gradient.linear.cyan_light_to_medium_dark};

  ${({ theme }) => theme.mq.xs} {
    margin-bottom: 30px;
  }
`

const StyledOptionTitle = styled.div`
  color: ${({ theme }) => theme.color.text.inverted};
  font-size: 2rem;
  text-transform: uppercase;
`

const StyledOptionSubTitle = styled.div`
  color: ${({ theme }) => theme.color.text.inverted};
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: 600;
`

const MvpGeneralText = ({ description, boxLeft, boxRight }) => (
  <StyledContainer>
    <GeneralText aboutContentTxt={description} />
    <Container>
      <StyledButtonWrapper>
        <StyledOption>
          <StyledOptionTitle>{boxLeft && boxLeft.title}</StyledOptionTitle>
          <StyledOptionSubTitle>{boxLeft && boxLeft.description}</StyledOptionSubTitle>
        </StyledOption>
        <StyledOption>
          <StyledOptionTitle>{boxRight && boxRight.title}</StyledOptionTitle>
          <StyledOptionSubTitle>{boxRight && boxRight.description}</StyledOptionSubTitle>
        </StyledOption>
      </StyledButtonWrapper>
    </Container>
  </StyledContainer>
)

MvpGeneralText.propTypes = {
  description: PropTypes.string.isRequired,
  boxLeft: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
  boxRight: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
}

export default MvpGeneralText
