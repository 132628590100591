import React from "react"

import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"

import TransitionWrapper from "components/common/Layout"
import Opinions from "components/common/Opinions"
import Seo from "components/common/Seo"
import SubpageHeader from "components/common/SubpageHeader"
import TrustUs from "components/common/TrustUs"

import CTASection from "../components/common/CTASection"
import E2EProjects from "../components/pages/howWeWork/E2EProjects"
import ServicesLink from "../components/pages/howWeWork/ServicesLink"
import MvpDescription from "../components/pages/mvp/MvpDescription"
import MvpGeneralText from "../components/pages/mvp/MvpGeneralText"
import MvpProcess from "../components/pages/mvp/MvpProcess"
import urls from "../config/urls"

const query = graphql`
  query MvpQuery {
    strapiMvp {
      page_title
      page_description
      MvpDescription {
        page_description
        page_title
        mvp_steps {
          title
        }
      }
      MvpProcess {
        description
        title
        mvp_process_arrow {
          title
        }
        mvp_process_step {
          description
          title
        }
      }
      MvpContact {
        title
        description
      }
      BoxLeft {
        title
        description
      }
      BoxRight {
        title
        description
      }
    }
    strapiHowWeWork {
      end_to_end_projects {
        implementation_tile {
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 400, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          realization {
            slug
          }
          id
          technologies
          title
        }
        section_title
      }
    }
  }
`

const Mvp = ({ location }) => {
  const { strapiHowWeWork, strapiMvp } = useStaticQuery(query)
  const boxLeft = {
    title: strapiMvp.BoxLeft.title,
    description: strapiMvp.BoxLeft.description,
  }
  const boxRight = {
    title: strapiMvp.BoxRight.title,
    description: strapiMvp.BoxRight.description,
  }
  return (
    <TransitionWrapper location={location}>
      <Seo seoPageName="MVP" title="Startup mvp" />
      <SubpageHeader subpageTitle={strapiMvp.page_title} />
      <MvpGeneralText boxLeft={boxLeft} boxRight={boxRight} description={strapiMvp.page_description} />
      <MvpDescription data={strapiMvp.MvpDescription} />
      <MvpProcess data={strapiMvp.MvpProcess} />
      <CTASection data={strapiMvp.MvpContact} to={urls.contact.url} />
      <TrustUs />
      <E2EProjects
        implementations={strapiHowWeWork.end_to_end_projects.implementation_tile}
        section_title={strapiHowWeWork.end_to_end_projects.section_title}
      />
      <ServicesLink />
      <Opinions />
    </TransitionWrapper>
  )
}

Mvp.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
}

export default Mvp
